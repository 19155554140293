import capitalize from 'lodash/capitalize';
import { type ReactNode, useEffect, useMemo, useState } from 'react';
import { useLatest } from 'react-use';

import { EnumsPlatform } from '@lp-lib/api-service-client/public';

import {
  AudienceControlMsgReceiverProvider,
  AudienceControlMsgSenderProvider,
} from '../../components/Audience/AudienceControlProviders';
import { BotProvider } from '../../components/Bot';
import { BroadcastProvider } from '../../components/Broadcast/BroadcastProvider';
import { ChatSharedContextProvider } from '../../components/Chat/SharedContext';
import { useExtSendChatNotifs } from '../../components/ChatNotifs/ChatNotifs';
import { CohostPositionManagerProvider } from '../../components/Cohost/CohostPositionManagerProvider';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../../components/ConfirmCancelModalContext';
import { ContextMenuProvider } from '../../components/ContextMenu/Context';
import {
  CrowdFramesExtractor,
  CrowdFramesProvider,
} from '../../components/CrowdFrames';
import { DeviceCheckModal } from '../../components/Device/Check';
import { PlayerVideoMixer } from '../../components/Device/video-stream-mixer';
import { ExperienceMetricsProvider } from '../../components/ExperienceMetrics';
import { ExperienceScoreProvider } from '../../components/ExperienceScore';
import {
  type FirebaseEvents,
  type FirebaseService,
  useFirebaseContext,
  useInitFirebase,
  useIsFirebaseConnected,
} from '../../components/Firebase';
import { FirebaseReconnectModal } from '../../components/Firebase/ReconnectModal';
import { FirebaseLatencyProvider } from '../../components/FirebaseLatency/Provider';
import { BlockProviders } from '../../components/Game/Blocks';
import { GamePlayMediaPlayerV2 } from '../../components/Game/Blocks/Common/GamePlay/GamePlayMedia';
import { useGamePlayMedia } from '../../components/Game/Blocks/Common/GamePlay/GamePlayProvider';
import { GamePlayUIConfigurationProvider } from '../../components/Game/Blocks/Common/GamePlay/GamePlayUIConfigurationProvider';
import { GameSessionPreconfigProvider } from '../../components/Game/Blocks/Common/GamePlay/GameSessionPreconfigProvider';
import { type GamePlayMedia } from '../../components/Game/Blocks/Common/GamePlay/types';
import { BlockLifecycleRulesEvaluatorProvider } from '../../components/Game/Blocks/Common/LifecycleRules/BlockLifecycleRulesEvaluator';
import { BlockKnifeUtils } from '../../components/Game/Blocks/Shared';
import { GameLibraryProvider } from '../../components/Game/GameLibrary';
import { GameCoverClip } from '../../components/Game/GamePackCoverPres';
import { GameSessionBundle } from '../../components/Game/GameSessionBundle';
import { GameStreamingStatusProvider } from '../../components/Game/GameStreamingStatusProvider';
import {
  useFetchGameSessionGamePack,
  useGameSessionBlock,
  useGameSessionStatus,
  useOndGameState,
} from '../../components/Game/hooks';
import { OnDGameControlProvider } from '../../components/Game/OndGameControl';
import { PostGameProvider } from '../../components/Game/PostGame/Provider';
import { PreGameProvider } from '../../components/Game/PreGame/Provider';
import { ScaleFontSizeForSmallWindowsHack } from '../../components/Game/ScaleFontSizeForSmallWindowsHack';
import { ControllerActionUtils } from '../../components/GameRecorder/types';
import { CameraIcon } from '../../components/icons/CameraIcon';
import { CameraOffIcon } from '../../components/icons/CameraOffIcon';
import { MicrophoneOffIcon } from '../../components/icons/MicrophoneOffIcon';
import { SpeakerIcon } from '../../components/icons/SpeakerIcon';
import { SafeZoneProvider } from '../../components/Layout/SafeZoneProvider';
import { LayoutAnchorProvider } from '../../components/LayoutAnchors/LayoutAnchors';
import { useLiteModeEnabled } from '../../components/LiteMode';
import { LobbyProvider } from '../../components/Lobby';
import { MicrophoneWithVolumeBar } from '../../components/MediaControls';
import { VolumeBalancerContainer } from '../../components/MediaControls/VolumeBalancer';
import { MusicPlayerProvider } from '../../components/MusicPlayer/Context';
import {
  MultipleUserInstancesWarning,
  useInitMultipleUserInstancesWatcher,
  useMultipleUserInstancesContext,
} from '../../components/MutipleInstances';
import {
  NotificationContextProvider,
  useNotificationDataSourceRedux,
} from '../../components/Notification/Context';
import {
  OnDGameHostingManagerProvider,
  useInitOnDGameHosting,
} from '../../components/OnDGameHosting';
import { OndGameBootstrapUIControlProvider } from '../../components/OnDGameUIControl';
import { PairingGameProvider } from '../../components/Pairing';
import { PersistentPointsProvider } from '../../components/PersistentPoints/Provider';
import { PixelFxProvider } from '../../components/PixelFx/PixelFxProvider';
import { PixelFxSceneProvider } from '../../components/PixelFx/PixelFxSceneProvider';
import { PlaybackModeProvider } from '../../components/PlaybackMode/Provider';
import { useMyInstance, useParticipantFlags } from '../../components/Player';
import { ProvidersList } from '../../components/ProvidersList';
import { RemoteRefreshProvider } from '../../components/RemoteRefresh/RemoteRefreshProvider';
import { RightPanelProvider } from '../../components/RightPanelContext';
import { SentimentProvider } from '../../components/Sentiment/Context';
import { SessionTracking } from '../../components/Session';
import { SFXProvider } from '../../components/SFX';
import { useInitStage } from '../../components/Stage';
import { StreamingToolsProvider } from '../../components/StreamingTools/StreamingToolsContext';
import { SwitchNoticeProvider } from '../../components/SwitchNotice';
import {
  useAutoJoinTeam,
  useEnsureTeamMemberRemovedWhenDisconnected,
  useEnsureUpdateTeamInfoInLocalStorage,
} from '../../components/TeamAPI/TeamV1';
import { TeamRandomizerProvider } from '../../components/TeamRandomizer';
import { TeamSizeContextProvider } from '../../components/TeamSizeControl/Context';
import { maxTeamMembers } from '../../components/TeamSizeControl/utils';
import { TownhallProvider } from '../../components/Townhall';
import {
  useUser,
  useUserContext,
  useUserStates,
} from '../../components/UserContext';
import {
  useInitVenueEvent,
  useMarkVenueInitCompleted,
  useMyClientId,
  useMyClientType,
  useParamVenueId,
  useSubscribeVenueEvent,
  useVenue,
  VenueBootstrap,
  VenueContextProvider,
  VenueInitLoading,
  VenueInitProvider,
  VenueSetup,
} from '../../components/Venue';
import { useFirebaseVenueSyncing } from '../../components/Venue/useFirebaseVenueSyncing';
import { VenueOrgLogoAverageColorProvider } from '../../components/VenueOrgLogoAverageColor/VenueOrgLogoAverageColorProvider';
import { VideoEffectsSettingsDummyStore } from '../../components/VideoEffectsSettings/Storage';
import { AdHocVOMsgReceiverProvider } from '../../components/VoiceOver/AdHocVOMsgProviders';
import {
  LocalizedVoiceoverPlayerProvider,
  LocalizedVoiceoversRTCPublisherProvider,
} from '../../components/VoiceOver/LocalizedVoiceOvers';
import { SubtitlesManagerProvider } from '../../components/VoiceOver/SubtitlesManagerProvider';
import {
  RTCServiceContextProvider,
  useAudienceRTCServiceMap,
  useDummyAudienceRTCServiceMap,
  useInitWebRTCMVMProcessor,
  useWebRTCMVMProcessor,
} from '../../components/WebRTC';
import { useZoomEnv } from '../../components/Zoom/utils';
import { ZoomVenueProvider } from '../../components/Zoom/ZoomVenueProvider';
import config from '../../config';
import { useEnsureUnlockedAudioContext } from '../../hooks/useEnsureUnlockedAudioContext';
import {
  getFeatureQueryParam,
  useFeatureQueryParam,
} from '../../hooks/useFeatureQueryParam';
import { useUpdateLoggerWithStreamSessionId } from '../../hooks/useInitLogger';
import { useInitReduxSlice } from '../../hooks/useInitRedux';
import { useInitSentryContext } from '../../hooks/useInitSentryContext';
import {
  useInitGameSession,
  useInitStreamSession,
} from '../../hooks/useInitSession';
import { useInstance } from '../../hooks/useInstance';
import { useInterceptNavigatorMediaSessionKeys } from '../../hooks/useInterceptNavigatorMediaSessionKeys';
import { useIsController, useIsCoordinator } from '../../hooks/useMyInstance';
import { usePageTitle } from '../../hooks/usePageTitle';
import { useRSCmp } from '../../hooks/useRSCmp';
import { useStatsAwareTaskQueue } from '../../hooks/useTaskQueue';
import { useUserWatcher } from '../../hooks/useUserWatcher';
import {
  getAudioContext,
  getEchoCancelledAudioDestination,
} from '../../services/audio/audio-context';
import { type ClientId } from '../../services/crowd-frames';
import { ClientType, type Venue } from '../../types';
import { type EmitterListener } from '../../utils/emitter';
import { HostStreamView } from './HostStreamView';
import { RemoteStreamStateProvider, TownhallTeamView } from './Team';
import { TeamRecovery } from './Team/Recovery';
import { UserRequired } from './UserRequired';
import { userIconRenderable } from './utils';

// Purposefully avoiding returning the entire object to prevent excessive
// comparisons/rerenders.
function useHasMe() {
  const me = useMyInstance();
  return !!me?.clientId;
}

type ZoomEnv = ReturnType<typeof useZoomEnv>;

function SyncGamePlayMedia(props: {
  setGamePlayMedia: (gamePlayMedia: GamePlayMedia | null) => void;
}) {
  const { setGamePlayMedia } = props;
  const gamePlayMedia = useGamePlayMedia();
  useEffect(() => {
    setGamePlayMedia(gamePlayMedia);
  }, [gamePlayMedia, setGamePlayMedia]);
  return null;
}

function GameInfoEntry(props: { label: string; value: string }) {
  return (
    <div className='flex items-center w-full'>
      <label className='w-1/3'>{props.label}:</label>
      <div className='w-2/3'>{props.value}</div>
    </div>
  );
}

function MicButton() {
  const me = useMyInstance();
  const flags = useParticipantFlags(me?.clientId);
  const micVolumeMeterProcessor = useWebRTCMVMProcessor();
  const userCtx = useUserContext();
  const { audio } = useUserStates();

  const handleToggleAudio = (val: boolean) => {
    if (!flags?.hasMicrophone) {
      return;
    }
    userCtx.toggleAudio(val);
  };

  return (
    <button
      type='button'
      className={`icon-btn w-16 h-16 rounded-none relative ${
        !flags?.hasMicrophone && 'bg-lp-red-001 hover:bg-lp-red-001'
      }`}
      data-testid='toggle-audio'
      onClick={() => handleToggleAudio(!audio)}
    >
      {audio ? (
        <MicrophoneWithVolumeBar processor={micVolumeMeterProcessor} />
      ) : (
        <MicrophoneOffIcon className='w-4.5 h-4.5 fill-current' />
      )}
    </button>
  );
}

function CamButton() {
  const me = useMyInstance();
  const flags = useParticipantFlags(me?.clientId);
  const { video } = useUserStates();
  const userCtx = useUserContext();

  const handleToggleVideo = (val: boolean) => {
    if (!flags?.hasCamera) {
      return;
    }
    userCtx.toggleVideo(val);
  };
  return (
    <button
      type='button'
      className={`icon-btn w-16 h-16 rounded-none ${
        !flags?.hasCamera && 'bg-lp-red-001 hover:bg-lp-red-001'
      }`}
      data-testid='toggle-video'
      onClick={() => handleToggleVideo(!video)}
    >
      {video ? (
        <CameraIcon className='w-4.5 h-4.5 fill-current' />
      ) : (
        <CameraOffIcon className='w-4.5 h-4.5 fill-current' />
      )}
    </button>
  );
}

function SpeakerButton() {
  const [open, setOpen] = useState(false);

  return (
    <div className='relative'>
      <button
        type='button'
        className={`icon-btn relative w-16 h-16 rounded-none ${
          false ? 'bg-lp-red-001 hover:bg-lp-red-001' : ''
        }`}
        onClick={() => setOpen(!open)}
      >
        <SpeakerIcon className='w-4.5 h-4.5 fill-current' />
      </button>
      <VolumeBalancerContainer isOpen={open} showTeam className='!-bottom-2' />
    </div>
  );
}

function GameInfo() {
  const pack = useFetchGameSessionGamePack();
  const state = useOndGameState();
  const block = useGameSessionBlock();
  const gss = useGameSessionStatus();
  const blockSummary = useMemo(
    () => (block ? BlockKnifeUtils.Summary(block) : null),
    [block]
  );
  const actionDesc = useMemo(() => {
    if (block?.type && gss) {
      return ControllerActionUtils.ForBlockAction(block.type, {
        gameSessionStatus: gss,
        timestamp: Date.now(),
      });
    }
    return null;
  }, [block, gss]);
  return (
    <div className='w-full bg-light-gray bg-opacity-50 text-white absolute bottom-0 flex flex-col'>
      <div className='w-full py-4'>
        <GameInfoEntry label='Game Pack' value={pack?.name ?? 'Not Loaded'} />
        <GameInfoEntry
          label='Game State'
          value={state ? capitalize(state) : 'Not Started'}
        />
        <GameInfoEntry
          label='Block'
          value={
            blockSummary
              ? `${blockSummary.prettyTypeName} - ${blockSummary.title}`
              : 'N/A'
          }
        />
        <GameInfoEntry
          label='Block Stage'
          value={actionDesc ? `${actionDesc.label}` : 'N/A'}
        />
      </div>
      <div className='border-t border-secondary flex items-center'>
        <MicButton />
        <CamButton />
        <SpeakerButton />
        <div className='h-16 flex-grow bg-black bg-opacity-60 border-l border-secondary'></div>
      </div>
    </div>
  );
}

function Lobby() {
  useAutoJoinTeam();
  return null;
}

function MobileTeamView() {
  const taskQueue = useStatsAwareTaskQueue({
    shouldProcess: true,
    stats: 'task-queue-townhall-join-ms',
  });

  return (
    <RemoteStreamStateProvider>
      <div
        className={`
        w-full h-full absolute
        overflow-hidden
        flex flex-col items-center justify-between
        pointer-events-off z-30`}
      >
        <div className={`z-5 flex flex-col items-center w-full relative`}>
          <TownhallTeamView
            taskQueue={taskQueue}
            teamView='default'
            maximumColsTheshold={4}
          />
        </div>
        <div></div>
      </div>
    </RemoteStreamStateProvider>
  );
}

const Audience = (props: {
  zoomEnv?: ZoomEnv;
  cohostEnabled: boolean;
  cohostVMEnabled: boolean;
}): JSX.Element => {
  useRSCmp('audience');

  const { zoomEnv, cohostEnabled } = props;
  const user = useUser();
  const userId = user.id;
  const { updateUserStates } = useUserContext();
  const clientId = useMyClientId();
  const clientType = useMyClientType();
  const hasMe = useHasMe();
  const { joined } = useUserStates();
  const firebaseConnected = useIsFirebaseConnected();
  const [venue] = useVenue();
  const [showDeviceCheckModal, setShowDeviceCheckModal] = useState(true);
  const notificationDataSource = useNotificationDataSourceRedux();
  const { multipleInstances } = useMultipleUserInstancesContext();
  const isController = useIsController();
  const isCoordinator = useIsCoordinator();
  const { svc, emitter } = useFirebaseContext();
  const liteMode = useLiteModeEnabled();
  const inZoom = !!zoomEnv;
  const sendChatNotifs = useExtSendChatNotifs();
  const [gamePlayMedia, setGamePlayMedia] = useState<GamePlayMedia | null>(
    null
  );

  useEffect(() => {
    if (joined) setShowDeviceCheckModal(false);
  }, [joined]);

  useEffect(() => {
    return () => {
      updateUserStates({ joined: false });
      setShowDeviceCheckModal(true);
    };
  }, [updateUserStates]);

  const isReady = joined && firebaseConnected && hasMe;

  if (multipleInstances) {
    return (
      <MultipleUserInstancesWarning
        clientId={clientId}
        venueName={venue?.displayName}
      />
    );
  }

  const providers = [
    <ExperienceScoreProvider />,
    <SFXProvider />,
    <ConfirmCancelModalProvider />,
    <SwitchNoticeProvider />,
    <LayoutAnchorProvider />,
    <PixelFxProvider />,
    <AudienceRTCServiceProvider clientId={clientId} readyToJoin={isReady} />,
    <CrowdFramesProvider
      liteModeEnabled={liteMode}
      userId={userId}
      myClientId={clientId as ClientId}
      iconRenderable={inZoom ? userIconRenderable : undefined}
    />,
    <NotificationContextProvider datasource={notificationDataSource} />,
    <LobbyProvider ready={isReady} svc={svc} />,
    <PlaybackModeProvider />,
    <PairingGameProvider svc={svc} />,
    <RightPanelProvider />,
    <MusicPlayerProvider
      audioContextGetter={getAudioContext}
      echoCancelledAudioDestinationGetter={getEchoCancelledAudioDestination}
    />,
    <TeamRandomizerProvider venueId={venue.id} />,
    <TeamSizeContextProvider
      venueId={venue.id}
      defaultMaxMembers={maxTeamMembers}
    />,
    liteMode ? null : <SentimentProvider />,
    <ChatSharedContextProvider />,
    <RemoteRefreshProvider />,
    <ContextMenuProvider />,
    <StreamingToolsProvider />,
    <TownhallProvider
      ready={isReady}
      svc={svc}
      autoToggleEnabled={getFeatureQueryParam(
        'townhall-ond-auto-activate-for-v3-game'
      )}
    />,
    <BroadcastProvider ready={isReady} />,
    <GameLibraryProvider />,
    <SubtitlesManagerProvider venueId={venue.id} />,
    <OnDGameControlProvider ready={isReady} svc={svc} emitter={emitter} />,
    <OnDGameHostingManagerProvider svc={svc} sendChatNotifs={sendChatNotifs} />,
    <PreGameProvider />, // ondgamebootstrapui depends on this
    <PostGameProvider />, // ondgamebootstrapui depends on this
    <GameSessionPreconfigProvider />,
    <CohostPositionManagerProvider ready={isReady} svc={svc} />, // ondgamebootstrapui depends on this
    <AudienceControlMsgSenderProvider venueId={venue.id} />,
    <BlockLifecycleRulesEvaluatorProvider />,
    <OndGameBootstrapUIControlProvider
      venueId={venue.id}
      gameLibraryType={inZoom ? 'zoom' : 'bottomPublicLibrary'}
    />,
    <GamePlayUIConfigurationProvider />,
    <PersistentPointsProvider userId={userId} />,
    <PixelFxSceneProvider />,
    <ExperienceMetricsProvider isReady={isReady} />,
    <FirebaseLatencyProvider
      isReady={isReady}
      clientId={clientId as ClientId}
      firebaseService={svc}
    />,
    <BotProvider />,
    <GameStreamingStatusProvider />,
    <AdHocVOMsgReceiverProvider venueId={venue.id} />,
    <SafeZoneProvider top={inZoom ? 'top-30' : undefined} />,
    <AudienceControlMsgReceiverProvider ready={isReady} venueId={venue.id} />,
    <LocalizedVoiceoversRTCPublisherProvider
      venueId={venue.id}
      readyToJoin={isReady}
    />,
    <LocalizedVoiceoverPlayerProvider
      venueId={venue.id}
      readyToJoin={isReady}
    />,
    <VenueOrgLogoAverageColorProvider />,
  ];

  const providerful = (
    <ProvidersList providers={providers}>
      <VenueSetup />
      <div className='h-full w-full'>
        {showDeviceCheckModal && (
          <DeviceCheckModal
            venueId={venue.id}
            layout='col'
            modalClassName='w-full'
            disableLegalInfo
            disableWelcomeInfo
          />
        )}
        <HostStreamView />
        {liteMode ? null : <CrowdFramesExtractor />}
        {isReady && (
          <>
            <TeamRecovery />
            <Lobby />
            <BlockProviders>
              <MobileTeamView />
              <div className='hidden'>
                <GameSessionBundle>
                  <SyncGamePlayMedia setGamePlayMedia={setGamePlayMedia} />
                </GameSessionBundle>
              </div>
            </BlockProviders>
          </>
        )}
        {isReady && (
          <div
            className='w-full h-auto border border-secondary rounded-xl absolute top-1/2 transform -translate-y-1/2'
            style={{ aspectRatio: '16/9' }}
          >
            {gamePlayMedia ? (
              <GamePlayMediaPlayerV2
                gamePlayMedia={gamePlayMedia}
                play={true}
                mode={'small'}
                layout='anchored'
              />
            ) : (
              <div className='w-full h-full bg-black bg-opacity-50 text-white flex items-center justify-center rounded-xl'>
                No Game Media
              </div>
            )}
          </div>
        )}
        {isReady && <GameInfo />}
        <ConfirmCancelModalRoot />
        {isReady && (
          <SessionTracking
            config={config.session}
            platform={inZoom ? EnumsPlatform.PlatformZoom : undefined}
          />
        )}
        {joined && <FirebaseReconnectModal />}
        <GameCoverClip id='game-cover-clip' />
      </div>
      <ScaleFontSizeForSmallWindowsHack />
    </ProvidersList>
  );

  return (
    <>
      <ReturnlessHooks
        venue={venue}
        clientId={clientId}
        clientType={clientType}
        isController={isController}
        isCoordinator={isCoordinator}
        svc={svc}
        emitter={emitter}
        zoomEnv={zoomEnv}
        cohostEnabled={cohostEnabled}
      />
      {providerful}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default function AudienceWrapper(): JSX.Element {
  const zoomEnv = useZoomEnv();
  const vbgEnabled = useFeatureQueryParam('virtual-background');
  const vbgEffects = useLatest(useUserStates().virtualBackgroundEffects);
  const mixer = useMemo(() => {
    return new PlayerVideoMixer({
      virtualBackground: { enabled: vbgEnabled, effects: vbgEffects.current },
    });
  }, [vbgEffects, vbgEnabled]);
  const vesStore = useInstance(() => new VideoEffectsSettingsDummyStore());
  return (
    <VenueInitProvider>
      <VenueContextProvider paramVenueId={useParamVenueId()}>
        {(venueId) => {
          return (
            <VenueBootstrap
              venueId={venueId}
              clientType={ClientType.Audience}
              providers={[<UserRequired />]}
              deviceDisabled={!!zoomEnv}
              mixer={mixer}
              vesStore={vesStore}
            >
              <ZoomVenueProvider zoomEnv={zoomEnv}>
                <Audience
                  zoomEnv={zoomEnv}
                  cohostEnabled={false}
                  cohostVMEnabled={false}
                />
              </ZoomVenueProvider>
            </VenueBootstrap>
          );
        }}
      </VenueContextProvider>
      <VenueInitLoading />
    </VenueInitProvider>
  );
}

function ReturnlessHooks(props: {
  venue: Venue;
  clientId: string;
  clientType: ClientType;
  isController: boolean;
  isCoordinator: boolean;
  svc: FirebaseService;
  emitter: EmitterListener<FirebaseEvents>;
  zoomEnv?: ZoomEnv;
  cohostEnabled: boolean;
}) {
  const {
    venue,
    clientId,
    clientType,
    isController,
    isCoordinator,
    svc,
    emitter,
    zoomEnv,
    cohostEnabled,
  } = props;

  const user = useUser();

  usePageTitle(user, venue);
  useInterceptNavigatorMediaSessionKeys();
  useInitOnDGameHosting();
  useUserWatcher(
    clientId,
    clientType,
    user,
    zoomEnv?.user.pic_url,
    cohostEnabled
  );
  useUpdateLoggerWithStreamSessionId();
  useInitFirebase(!!user.id && !!venue.id);
  useInitReduxSlice(clientId, venue.id);
  useInitMultipleUserInstancesWatcher(clientId, clientType);
  useEnsureUnlockedAudioContext();
  useInitSentryContext(venue.id);
  useInitStreamSession(isController);
  useInitGameSession({
    isController,
    initialSetup: false,
  });
  useInitStage();
  useEnsureTeamMemberRemovedWhenDisconnected(svc, emitter);
  useEnsureUpdateTeamInfoInLocalStorage();
  useInitVenueEvent(); // coordinator only
  useSubscribeVenueEvent();
  useFirebaseVenueSyncing(isCoordinator);
  useMarkVenueInitCompleted(!!user.id);

  return null;
}

function AudienceRTCServiceProvider(props: {
  clientId: string;
  readyToJoin: boolean;
  children?: ReactNode;
}) {
  const { clientId, readyToJoin } = props;
  const audienceRtcServiceMap = useAudienceRTCServiceMap(clientId);
  const dummyRTCServiceMap = useDummyAudienceRTCServiceMap(clientId);
  const rtcServiceMap = getFeatureQueryParam('stream-use-dummy')
    ? dummyRTCServiceMap
    : audienceRtcServiceMap;

  useInitWebRTCMVMProcessor(rtcServiceMap.stage);

  return (
    <RTCServiceContextProvider
      readyToJoin={readyToJoin}
      rtcServiceMap={rtcServiceMap}
    >
      {props.children}
    </RTCServiceContextProvider>
  );
}
